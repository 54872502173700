<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">

            <h4>
              Painel de Atividades
            </h4>
          </div>
          <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="sidebar-menu-list scroll-area"
          >
            <div class="px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Modos de Exibição
              </h6>

            </div>
            <!-- Filters -->
            <b-list-group class="list-group-filters">

              <b-list-group-item
                  v-for="filter in modosExibicao"
                  :key="filter.title + $route.path"
                  class="font-small-3"
                  :to="atualizarRota(filter.route)"
                  :active="isDynamicRouteActive(filter.route)"
                  @click="$emit('close-left-sidebar')"
              >

                <feather-icon
                    :icon="filter.icon"
                    size="18"
                    class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-2 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Filtros da lista
              </h6>

            </div>

            <b-list-group class="list-group-labels" v-if="exibirFiltros">

              <b-list-group-item
                  @click="$emit('close-left-sidebar')"
                  v-if="$can('filtros_coordenacao', 'atividades')"
              >
                <select-com-pesquisa
                    ref="selectTimes"
                    :itens-para-edicao="[]"
                    :multiple="true"
                    placeholder="Time"
                    url="atividades/times"
                    @selecionados="tratarEventoTimesSelecionados"
                />
              </b-list-group-item>
              <b-list-group-item
                  @click="$emit('close-left-sidebar')"
                  v-if="$can('filtros_coordenacao', 'atividades')"
              >
                <select-com-pesquisa
                    ref="selectResponsaveis"
                    :itens-para-edicao="[]"
                    :multiple="true"
                    placeholder="Responsável"
                    url="atividades/responsaveis"
                    @selecionados="tratarEventoResponsaveisSelecionados"
                />
              </b-list-group-item>
              <b-list-group-item
                  @click="$emit('close-left-sidebar')"
                  v-if="$can('filtros_coordenacao', 'atividades')"
              >
                <select-com-pesquisa
                    ref="selectExecutantes"
                    :itens-para-edicao="[]"
                    :multiple="true"
                    placeholder="Executantes"
                    url="users"
                    @selecionados="tratarEventoExecutantesSelecionados"
                />
              </b-list-group-item>
              <b-list-group-item
                  @click="$emit('close-left-sidebar')"

              >
                <select-com-pesquisa
                    ref="selectClientes"
                    :itens-para-edicao="[]"
                    placeholder="Cliente"
                    :multiple="true"
                    url="atividades/clientes"
                    @selecionados="tratarEventoClientesSelecionados"
                />
              </b-list-group-item>

              <b-list-group-item
                  @click="$emit('close-left-sidebar')"
              >
                <select-com-pesquisa
                    ref="selectProjetos"
                    :itens-para-edicao="[]"
                    :multiple="false"
                    placeholder="Projeto"
                    url="atividades/projetos"
                    @selecionados="tratarEventosProjetosSelecionados"
                />
              </b-list-group-item>

              <b-list-group-item
                  @click="$emit('close-left-sidebar')"
              >
                <select-com-pesquisa
                    v-if="filtrosAtividades.selectProjetos"
                    ref="selectDisciplinas"
                    :itens-para-edicao="[]"
                    :multiple="true"
                    placeholder="Disciplinas"
                    :url="`disciplinas?projetos[]=${filtrosAtividades.selectProjetos.id}`"
                    @selecionados="tratarEventosDisciplinasSelecionadas"
                />
              </b-list-group-item>

              <b-list-group-item
                  @click="$emit('close-left-sidebar')"
              >
                <select-com-pesquisa
                    v-if="filtrosAtividades.selectProjetos"
                    ref="selectLocalizacoes"
                    :itens-para-edicao="[]"
                    :multiple="true"
                    placeholder="Localização"
                    :url="`/localizacoes?projeto=${filtrosAtividades.selectProjetos.id}`"
                    @selecionados="tratarEventosLocalizacaoSelecionada"

                />
              </b-list-group-item>
              <b-list-group-item
                  @click="$emit('close-left-sidebar')"
              >
                <label class="text-dark text-uppercase">Data Entrega Projeto</label>

                <flat-pickr
                    v-model="filtrosAtividades.rangeDate"
                    class="form-control mb-2"
                    :config="{ locale: traducaoCaledario,mode: 'range', dateFormat: 'd/m/Y',
                             altFormat: 'd/m/Y', onChange: this.onDateChange}"
                />
              </b-list-group-item>

            </b-list-group>

            <div class="d-flex justify-content-between px-2" v-if="exibirFiltros">
              <b-button
                  type="button"
                  variant="outline-success"
                  @click="filtrarAtividades"
              >
                Filtrar
              </b-button>
              <b-button
                  type="button"
                  variant="outline-danger"
                  @click="limparFiltros"
              >
                Limpar
              </b-button>
            </div>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BCol, BListGroup, BListGroupItem, } from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import { isDynamicRouteActive, useRouter } from '@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import traducao from '@/views/components/componentesPensou/traducoes/flat-pickr-pt'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCol,
    SelectComPesquisa,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    flatPickr,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
    routeAtual: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      route,
    } = useRouter()

    const filtroAtivo = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const traducaoCaledario = traducao.pt

    const taskFilters = ref([
      {
        title: `Em Desenvolvimento(${store.state.atividades.modosExibicao[0].filtrado})`,
        value: 'Em_Desenvolvimento',
        icon: 'InboxIcon',
        route: {
          name: 'apps-todo-filter',
          params: { filter: 'Em_Desenvolvimento' },
        },
      },
      {
        title: `Em Aprovação(${store.state.atividades.modosExibicao[1].filtrado})`,
        value: 'Em_Aprovação',
        icon: 'ClockIcon',
        route: {
          name: 'apps-todo-filter',
          params: { filter: 'Em_Aprovação' },
        },
      },
      {
        title: `Reprovadas(${store.state.atividades.modosExibicao[2].filtrado})`,
        value: 'Reprovadas',
        icon: 'XCircleIcon',
        route: {
          name: 'apps-todo-filter',
          params: { filter: 'Reprovada' },
        },
      },
      {
        title: `Concluídas(${store.state.atividades.modosExibicao[3].filtrado})`,
        value: 'Concluídas',
        icon: 'CheckCircleIcon',
        route: {
          name: 'apps-todo-filter',
          params: { filter: 'Concluída' },
        },
      },
      {
        title: `Todas(${store.state.atividades.modosExibicao[4].filtrado})`,
        value: 'Todas',
        icon: 'ListIcon',
        route: {
          name: 'apps-todo-filter',
          params: { filter: 'Todas' },
        },
      },
    ])

    const modoExibicao = computed(() => store.state.atividades.modosExibicao)
    watch(route, (newRoute, oldRoute) => {

      filtroAtivo.value = false

      // Add your logic here to handle route changes
    })

    watch(store.state.atividades.modosExibicao, () => {

      const modosExibicao = store.state.atividades.modosExibicao
      taskFilters.value.forEach(filter => {

        let titleText = modosExibicao.find(obj => obj.value === filter.value)

        if (!filtroAtivo.value) {
          filter.title = `${titleText.text} (${titleText.total})`
        } else {
          filter.title = `${titleText.text} (${titleText.filtrado})`
        }

      })
    })

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
      traducaoCaledario,
      filtroAtivo
    }
  },
  data() {
    return {
      filtrosAtividades: {
        rangeDate: null,
        selectClientes: null,
        selectTimes: null,
        selectResponsaveis: null,
        selectProjetos: null,
        selectLocalizacoes: null,
        selectChecklists: null,
        selectExecutantes: null
      },
    }
  },
  computed: {
    modosExibicao() {
      return this.taskFilters
    },
    exibirFiltros() {

      return this.$store.state['atividades'] && this.$store.state['atividades'].modosExibicao[4].total > 0
    },
  },

  methods: {
    atualizarRota(route) {

      return {
        name: route.name,
        params: {
          filter: route.params.filter,
        },
        query: {
          times: this.$route.query.times,
          projetos: this.$route.query.projetos,
          clientes: this.$route.query.clientes,
          users: this.$route.query.users,
          localizacoes: this.$route.query.localizacoes,
          disciplinas: this.$route.query.disciplinas,
          executantes: this.$route.query.executantes,

        },
      }
    },
    filtrarAtividades() {
      this.$emit('aplicar-filtros')
      this.filtroAtivo = true
    },
    limparFiltros() {
      const selects = [
        'selectClientes',
        'selectTimes',
        'selectResponsaveis',
        'selectProjetos',
        'selectLocalizacoes',
        'selectChecklists',
        'selectDisciplinas',
        'selectExecutantes'
      ]

      selects.forEach(select => {
        if (this.$refs[select]) {
          this.filtrosAtividades[select] = null
          this.$refs[select].resetarConteudo()
        }
      })
      this.filtrosAtividades.rangeDate = []

      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({
          path: this.$route.path,
          params: {},
        })
      }
    },

    tratarEventoClientesSelecionados(itens) {
      this.$route.query.clientes = itens.map(item => item.id)
      this.filtrosAtividades.selectClientes = itens
    },

    onDateChange(selectedDates) {
      this.$route.query.periodo = selectedDates.map(date => date.toLocaleDateString('pt-BR'))
    },
    tratarEventosProjetosSelecionados(itens) {

      this.$route.query.projetos = itens && [itens.id]
      this.filtrosAtividades.selectProjetos = itens
    },
    tratarEventoResponsaveisSelecionados(itens) {
      this.$route.query.users = itens.map(item => item.id)
      this.filtrosAtividades.selectResponsaveis = itens
    },
    tratarEventoExecutantesSelecionados(itens) {
      this.$route.query.executantes = itens.map(item => item.id)
      this.filtrosAtividades.selectExecutantes = itens
    },
    tratarEventoTimesSelecionados(itens) {
      this.$route.query.times = itens.map(item => item.id)
      this.filtrosAtividades.selectTimes = itens
    },
    tratarEventosDisciplinasSelecionadas(itens) {
      this.$route.query.disciplinas = itens.map(item => item.id)
      this.filtrosAtividades.selectDisciplinas = itens
    },
    tratarEventosLocalizacaoSelecionada(itens) {
      this.$route.query.localizacoes = itens.map(item => item.id)
      this.filtrosAtividades.selectLocalizacoes = itens
    },

  },
}
</script>
